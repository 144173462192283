.button {
  display: inline-block;
  border: 1px solid white;
  color: white;
  padding: 7px 12px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  text-decoration: none;

  .content {
    padding-top: 2px;
    padding-bottom: 1px;
    border-bottom: solid 1px rgba(255, 255, 255, 0);
    transition: border-color 300ms;
  }

  &:hover .content {
    border-color: rgba(255, 255, 255, 1);
  }
}
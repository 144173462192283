.main-wrapper {
  position: absolute;
  padding: 50px;

  .main-container {
    margin: 80px;
  }

  .main-container-m {
    margin: auto;
  }
}
.cv-wrapper {
  padding: 50px;

  .cv-container {
    width: 80%;
    margin: auto;
  }

  .cv-container-m {
    margin: auto;
  }
}
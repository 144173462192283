.link {
  display: inline-block;
  color: white;
  position: relative;

  .content {
    position: relative;
    box-sizing: border-box;
    text-decoration: none;
  }

  &:after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 10px; // Adjust as needed
    background-image: url(../assets/wave.svg);
    background-size: 15px 10px;
    animation: move 20s linear infinite;
    animation-play-state: paused;
  }

  &:hover::after {
    animation-play-state: running;
  }

  @keyframes move {
    from {
      background-position: 0 20px;
    }
    to {
      background-position: 500px 20px;
    }
  }
}
.cursor-container {
  mix-blend-mode: difference;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;

  .cursor {
    position: absolute;
    width: 32px;
    height: 32px;
    transform: translate(-50%, -50%);

    .pointer {
      position: absolute;
    }

    .brackets {
      position: absolute;
      width: 32px;
      height: 32px;
      transition: transform 150ms ease-in-out;

      .bracket {
        position: fixed;
        transition: transform 100ms ease-in-out;
      }
    }
  }
}


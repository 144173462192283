.nf-wrapper {
  position: absolute;
  padding: 50px;

  .nf-container {
    width: 80%;
    margin: auto;
  }

  .nf-container-m {
    margin: auto;
  }
}
* {
  cursor: none !important;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.show-cursor {
    cursor: auto !important;
  }

}

:root {
  --widthmod: 100vw;
  --heightmod: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;

  font-family: ballinger-mono, sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 1.2em;
  color: white;
}

h1 {
  font-weight: 200;
  font-size: 1.6em;
  white-space: nowrap;
}

h2 {
  font-weight: 200;
  font-size: 1.45em;
  white-space: nowrap;
}


::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
  background: transparent;
}

::-moz-scrollbar {
  display: none;
  width: 0;
  height: 0;
  background: transparent;
}

body {
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;

  .container-wrapper {
    top: 0;
    bottom: 0;

    .switch-fade-enter {
      opacity: 0;
    }

    .switch-fade-enter-active {
      opacity: 1;
      transition: opacity 300ms;
    }

    .switch-fade-exit {
      opacity: 1;
    }

    .switch-fade-exit-active {
      opacity: 0;
    }
  }
}


.scrollbar {
  position:fixed;
  height: 100vh;
  width: 50px;
  right: 0;

  &.fadein {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.noshow {
    opacity: 0;
  }

  .bar {
    background: white;
    width: 1px;
    height: 100vh;
    margin: auto;
  }

  .scroller {
    position:fixed;
    background: black;
    width: 5px;
    border: solid 1px white;
    margin-left: 22px;
    top: 0;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}